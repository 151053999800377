import "../less/all.less"

$(document).ready(function () {
    $("#show_menu").on('click', function () {
        $('body').toggleClass("show-menu")
    });

    $(".password__toggle").on('click', function (e) {
        $(this).parent().find("input").each(function () {
            if ($(this).attr("type") == "password") {
                $(this).attr("type", "text");
            } else {
                $(this).attr("type", "password");
            }
        });
    });

    $("input").keypress(function (e) {
        var key = e.which || e.keyCode;
        if ($(this).attr("type") == "password") {
            var s = String.fromCharCode(e.which);
            if (s.toUpperCase() === s && s.toLowerCase() !== s && !e.shiftKey) {
                $(this).siblings(".capslock").text(gettext("Attention: CAPS LOCK is ON"));
            } else {
                $(".capslock").text("");
            }
        } else {
            $(".capslock").text("");
        }
    });

    $("input").blur(function (e) {
        $(".capslock").text("");
    });

    const focusClass = 'focusVisible'

    function handleFirstTab(evt) {
        if (evt.key === 'Tab') {
            document.body.classList.add(focusClass)

            window.removeEventListener('keydown', handleFirstTab)
            window.addEventListener('mousedown', handleFirstMouseDown)
        }
    }

    function handleFirstMouseDown() {
        document.body.classList.remove(focusClass)

        window.removeEventListener('mousedown', handleFirstMouseDown)
        window.addEventListener('keydown', handleFirstTab)
    }

    window.addEventListener('keydown', handleFirstTab)

    var theme_bg = $('.account-theme').data('bg');

    if (theme_bg) {
        $('.account-theme').css('background-image', 'url(' + theme_bg + ')');
    }
});
